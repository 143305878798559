@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./iransans.scss";
@import "react-toastify/dist/ReactToastify.css";

body {
  font-family: IRANSans, Arial, Helvetica, sans-serif;
}

.font-iran-sans {
  font-family: IRANSans, Arial, Helvetica, sans-serif !important;
}

@keyframes modalFade {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 500ms ease-in-out;
  animation-name: modalFade;
  animation-duration: 0.3s;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 99 !important;
}

.Toastify__toast-body {
  font-family: IRANSans, Arial, Helvetica, sans-serif !important;
}
